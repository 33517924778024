<script>
import { ref } from 'vue';

export default {
  name: 'creationView',
  setup() {
    // 각 섹션을 위한 ref를 선언합니다.
    const digestSection = ref(null);
    const referenceSection = ref(null);
    const styleSection = ref(null);

    // 스크롤 이동 메서드를 반환합니다.
    const scrollToSection = (section) => {
      // console.log(section)
      const element = section;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    return {
      digestSection,
      referenceSection,
      styleSection,
      scrollToSection, // 스크롤 이동 메서드를 반환합니다.
    };
  },
  data () {
    return {
      Uid: '',
      projectCode: '',
      overlay:false,
      expertBtn: false,
      creationStyle: 'press_release',
      creationStyleResult: [],
      creationStyleTransResult: [],
      creationReferenceResult: [],
      creationDigestResult: [],
      timestamp: new Date().getTime(),
      targetLanguage: '',
      loading: false,
      projectData:{}
    }
  },
  methods: {
    expertBtnToggle() {
      this.expertBtn = !this.expertBtn;
    },
    exportFile(item) {
      return "https://transcreation.lexcodeapi.com/tc/download/" + this.Uid + "/" + this.projectCode + "/" + item
    },
    async creation_style_datas(style) {
        this.creationStyle = style;
        this.creationStyleResult = [];
        this.creationStyleTransResult = [];
        const myData = {
            param1: this.projectCode, // PJ CODE
            param2: this.Uid, // UID
            param3: this.timestamp, // TIMESTAMP
            param4: this.creationStyle, // STYLE
            param5: this.targetLanguage, // TRG LANG
        };
        console.log(myData)
        const websocket = new WebSocket(`wss://transcreation.lexcodeapi.com/tc/ws_style/${myData.param1}<!@!>${myData.param2}<!@!>${myData.param3}<!@!>${myData.param4}<!@!>${myData.param5}<!@!>trans`)

        websocket.onopen = (event) => {
          console.log("Connected to WebSocket", event)
          // 연결 성공 시 처리할 로직
        }

        websocket.onmessage = (event) => {
          // console.log("Message from server ", event.data)
          this.loading = true;
          this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
          // 서버로부터 메시지를 받았을 때 처리할 로직
          // Check if the message contains 'total' information
          const totalMatch = event.data.match(/<!@!>total:(\d+)<!@!>/);
          let page = 1;
          if (totalMatch) {
              const total = parseInt(totalMatch[1]);
              if (total > 0) {
                // console.log(page)
                // console.log(total)
                // console.log("=================================================")
                if(total >= page){
                  websocket.send(page);
                }
                page++;
              }
          } else {
            this.creationStyleResult += event.data
            // console.log(event.data)
          }
        }

        websocket.onerror = (error) => {
          console.error("WebSocket Error", error)
          // 에러 처리 로직
        }

        websocket.onclose = (event) => {
          console.log("WebSocket connection closed", event)
          if (myData.param5 !== 'Korean'){
            this.generate_style_datas_by_lang()
          }
          // 연결이 닫혔을 때 처리할 로직
        }
      },
    async generate_style_datas_by_lang() { // 스타일 윤문 2
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/generate_style_datas_by_lang",
          {
            style: this.creationStyle,
            lang: 'Korean',
          },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          await this.read_style_datas()
        } else {
          console.log("generate_style_datas_by_lang error")
        }
      } catch (error) {
        console.error(error)
      }
    },
    async read_style_datas() { // 스타일 윤문 3
      try {
        const response = await this.$axios.get(
          `https://transcreation.lexcodeapi.com/tc/read_style_datas?style=${this.creationStyle}`,
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.loading = false;
          this.creationStyleTransResult = response.data.style_datas.translated
        }
      } catch (error) {
        console.error(error)
      }
    },
    async update_trans_digest_datas() { // 키워드와 핵심요약 2
      try {
        const response = await this.$axios.put(
          "https://transcreation.lexcodeapi.com/tc/update_trans_digest_datas", {
          lang: this.projectData.tgt_lang
        },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          // this.read_digest_datas()
          return response.data.result;
        }
      } catch (error) {
        console.error(error)
      }
    },
    async read_digest_datas() { // 키워드와 핵심요약 3
      try {
        const response = await this.$axios.get(
          "https://transcreation.lexcodeapi.com/tc/read_digest_datas",
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode
            }
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.creationDigestResult = response.data.digest_datas
        }
      } catch (error) {
        console.error(error)
        this.overlay = false
      } finally {
        this.overlay = false
      }
    },

    //add start
    async read_project() {
      try {
        const response = await this.$axios.get(`https://transcreation.lexcodeapi.com/tc/read_project/${this.projectCode}`, {
            headers: {
              Uid: this.Uid,
            }
          });

        if(response.data.result) {
          this.projectData = response.data.project;
          return true;
        }
      } catch(err) {
        console.error('onlyReadProjectData : ', err)
      }
    },
    async create_keyword_data_by_file() {
      try {
        const response = await this.$axios.post("https://transcreation.lexcodeapi.com/tc/create_keyword_data_by_file", {
          lang: this.projectData.src_lang,
        },
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );

        if(response.data.result) {
          return response.data.result;
        }
      } catch(err) {
        console.error(err);
      }
    },
    async create_digest_data_by_file() {
      try {
        const response = await this.$axios.post("https://transcreation.lexcodeapi.com/tc/create_digest_data_by_file", {
          target: "digest_origin",
          lang: this.projectData.src_lang
        },
        {
          headers: {
            Uid: this.Uid,
            ProjectCode: this.projectCode,
            'Content-Type': 'application/json',
          },
        });

        if(response.data.result) {
          return response.data.result;
        }
      } catch(err) {
        console.error(err);
      }
    },

    async parrellelProcessingDK() {
      try {
        this.overlay = true;
        const [digestData, keywordData] = await Promise.all([
          this.create_digest_data_by_file(),
          this.create_keyword_data_by_file()
        ]);

        let isUpdated = false;

        if (digestData && keywordData) {
          isUpdated = await this.update_trans_digest_datas();
          if(isUpdated) {
            await this.read_digest_datas();
            this.overlay = false;
          }
        }
      } catch (error) {
        console.error('Error:', error);
        this.overlay = false;
      }
    },

    async create_reference_datas() {
      this.overlay = true
      try {
        const response = await this.$axios.post(
          "https://transcreation.lexcodeapi.com/tc/create_reference_datas", {},
          {
            headers: {
              Uid: this.Uid,
              ProjectCode: this.projectCode,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data);
        if (response.data.result === true) {
          this.creationReferenceResult = response.data.reference
        } else {
          console.log("create_reference_datas error")
        }
      } catch (error) {
        console.error(error)
        this.overlay = false
      } finally {
        this.overlay = false
      }
    },
    //add end

    getKoreanStyleText(style) {
      switch (style) {
        case 'press_release':
          return '보도자료';
        case 'product_manual':
          return '매뉴얼';
        case 'marketing_materials':
          return '홍보자료';
        case 'speeches_presentations':
          return '연설/발표문';
        case 'ppt_presentation':
          return '발표자료';
        case 'legal_documents':
          return '법률문서';
        case 'educational_material':
          return '교육자료';
        case 'reports':
          return '보고서';
        case 'blog_post':
          return '블로그 포스트';
        case 'social_media_post':
          return 'SNS 포스트';
        default:
          return '';
      }
    },
    getTargetLangText(trglang) {
      switch (trglang) {
        case 'Korean':
          return '한국어';
        case 'English':
          return '영어';
        case 'Japanese':
          return '일본어';
        case 'Chinese(Simplified)':
          return '중국어 간체';
        case 'Spanish':
          return '스페인어';
        case 'Tagalog':
          return '필리핀어';
        default:
          return '';
      }
    },
  },
  async mounted() {
    // this.BASE_SERVER_URL = this.$BASE_SERVER_URL;
    // this.socket = this.$socket;
    // this.connectWebSocket();
    this.Uid = sessionStorage.getItem('uID');
    this.projectCode = this.$route.query.project_code
    this.targetLanguage = this.$route.query.trg_lang
    await this.creation_style_datas(this.creationStyle)

    await this.read_project();
  }
}
</script>

<template>
  <div class="creation">
    <div class="process_wrap">
      <div class="step">
        <p class="num">1</p>
        <p class="process">설정</p>
      </div>
      <router-link :to="`/translate?project_code=${projectCode}`" class="step">
        <p class="num">2</p>
        <p class="process">번역</p>
      </router-link>
      <div class="step active">
        <p class="num">3</p>
        <router-link :to="`/creation?project_code=${projectCode}`" class="process">Creation</router-link>
      </div>
      <div class="step">
        <p class="num">4</p>
        <p class="process" @click="expertBtnToggle()">추출<img src="../assets/img/chevron.png" :class="{'rotate' : expertBtn}" alt="chevron" /></p>
        <div class="quickBtn_wrap export" v-if="expertBtn">
          <a :href="exportFile('origin')">
            <label>원문 파일</label>
            <img src="../assets/img/download_icon.png" alt="download icon"/>
          </a>
          <a :href="exportFile('trans')">
            <label>번역문 파일</label>
            <img src="../assets/img/download_icon.png" alt="download icon"/>
          </a>
          <a :href="exportFile('glo')">
            <label>용어집 파일</label>
            <img src="../assets/img/download_icon.png" alt="download icon"/>
          </a>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="overlay">
      <div class="loading">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
    <div class="creaion_wrap">
      <div class="creation_list_wrap">
        <div class="creation_list" @click="scrollToSection(styleSection)">
          <p class="creation_list_title">스타일 윤문</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'press_release'}" @click="creation_style_datas('press_release')">보도자료</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'reports'}" @click="creation_style_datas('reports')">보고서</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'product_manual'}" @click="creation_style_datas('product_manual')">매뉴얼</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'marketing_materials'}" @click="creation_style_datas('marketing_materials')">홍보자료</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'speeches_presentations'}" @click="creation_style_datas('speeches_presentations')">연설/발표문</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'ppt_presentation'}" @click="creation_style_datas('ppt_presentation')">발표자료</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'legal_documents'}" @click="creation_style_datas('legal_documents')">법률문서</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'educational_material'}" @click="creation_style_datas('educational_material')">교육자료</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'blog_post'}" @click="creation_style_datas('blog_post')">블로그 포스트</p>
          <p class="creation_list_label" :class="{active : creationStyle === 'social_media_post'}" @click="creation_style_datas('social_media_post')">SNS 포스트</p>
        </div>
        <div class="creation_list" @click="scrollToSection(digestSection)">
          <p class="creation_list_title">키워드와 핵심요약</p>
        </div>
        <div class="creation_list" @click="scrollToSection(referenceSection)">
          <p class="creation_list_title">레퍼런스</p>
        </div>
      </div>
      <div class="creation_result_wrap">
        <div class="creation_result style_wrap" ref="styleSection">
          <h3>스타일 윤문 : {{ getKoreanStyleText(creationStyle) }}</h3>
          <p class="creation_list_label">{{ getTargetLangText(targetLanguage) }}로 윤문</p>
          <p class="creation_list_label" v-if="targetLanguage !== 'Korean'">출발어 기준 윤문</p>
          <textarea class="style origin" ref="scrollContainer" v-model="creationStyleResult" readonly :class="{ korean : targetLanguage === 'Korean', [targetLanguage.substring(0,7)]: true }">
            </textarea>
          <div class="style trans" v-if="targetLanguage !== 'Korean'" :class="{ 'text_loading' : loading  }">
            <template v-if="loading">
              <p class="loading">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </p>
          </template>
            <p v-for="(item, index) in creationStyleTransResult" :key="index">
              {{ item }}
            </p>
          </div>
        </div>
        <div class="creation_result digest_wrap" ref="digestSection">
          <h3>키워드와 핵심요약</h3>
          <template v-if="creationDigestResult.digest_origin != null">
            <p class="creation_list_label">키워드</p>
            <div class="keyword">
              <p v-for="(item, index) in creationDigestResult.keywords" :key="index">{{ item }}</p>
            </div>
            <div class="digest_wrap">
              <p class="creation_list_label">핵심요약</p>
              <div class="digest origin">
                {{ creationDigestResult.digest_origin }}
              </div>
              <p class="creation_list_label">핵심요약의 번역</p>
              <div class="digest trans">
                {{ creationDigestResult.digest_trans }}
              </div>
            </div>
          </template>
          <template v-else>
            <p class="btn" @click="parrellelProcessingDK()">키워드 불러오기</p>
          </template>
        </div>
        <div class="creation_result reference_wrap" ref="referenceSection">
          <h3>키워드 별 레퍼런스</h3>
          <div class="reference">
            <template v-if="creationReferenceResult.length > 0">
              <a class="reference_cont" v-for="(item, index) in creationReferenceResult" :key="index" :href="item[index][4]" target="_blank">
                <p class="reference_keyword">{{ item[index][0] }}</p>
                <p class="reference_title">{{ item[index][1] }}</p>
                <p class="reference_description">{{ item[index][2] }}</p>
                <img src="../assets/img/chevron.png" alt="chevron"/>
              </a>
            </template>
            <template v-else>
              <p class="btn" @click="create_reference_datas()">레퍼런스 불러오기</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
